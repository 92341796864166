import * as React from 'react';
import { Text, View } from 'react-native';

import { DataTable, Button, Searchbar } from 'react-native-paper';

import { paginatedQuery } from "../../helpers/paginatedQuery";

import { functions } from "../../../App";
import { httpsCallable } from 'firebase/functions';

import { CheckAndCreate } from "../../components/CheckAndCreate";

export function AirlinesScreen({ navigation }) {
  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [items, setItems] = React.useState([]);
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    setPage(0);
  }, [itemsPerPage]);

  React.useEffect(() => {
    if(search !== ""){
      paginatedQuery("airlines", admin.firestore.FieldPath.documentId(), items, setItems, itemsPerPage);
    } else {
      paginatedQuery("airlines", admin.firestore.FieldPath.documentId(), items, setItems, itemsPerPage, "name_localized.en", search);
    }
  }, [page, itemsPerPage]);

  React.useEffect(() => {
    setItems([]);
    setPage(0);

    if(search !== ""){
      paginatedQuery("airlines", admin.firestore.FieldPath.documentId(), items, setItems, itemsPerPage, "name_localized.en", search);
    } else {
      paginatedQuery("airlines", admin.firestore.FieldPath.documentId(), items, setItems, itemsPerPage);
    }
  }, [search]);

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center'}}>
      <Button onPress={() => httpsCallable(functions, 'checkAirlinesTranslationsTriggerable')().then((response) => console.log(response))}>Check Translations</Button>
      <CheckAndCreate checkFunction='checkAirlinesTriggerable' createFunction='createAirlinesTriggerable'/>
      <Searchbar
        placeholder="Search"
        onChangeText={setSearch}
        value={search}
      />
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>ID</DataTable.Title>
          <DataTable.Title>English Name</DataTable.Title>
        </DataTable.Header>

        {items.map((x, i) =>
          <DataTable.Row key={i} onPress={() => (navigation.push('AirlineDetails', {id: x.id}))}>
            <DataTable.Cell>{ x.id }</DataTable.Cell>
            <DataTable.Cell>{ x.name_localized.en }</DataTable.Cell>
          </DataTable.Row>
        )}

        <DataTable.Pagination
          page={page}
          onPageChange={(page) => setPage(page)}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          showFastPagination
        />
      </DataTable>
    </View>
  );
}