import { collection, getDocs, query, startAfter, limit, orderBy, where } from "firebase/firestore";
import { db } from "../../App";

export const paginatedQuery = (collectionName, orderByAttribute, items, setItems, itemsPerPage, searchAttribute, searchTerm) => {
    //run the firebase query when page is changed
    var q;

    if(itemsPerPage === undefined){
      q = query(collection(db, collectionName), orderBy(orderByAttribute));
    }
    else if(items[items.length - 1] === undefined){
      q = query(collection(db, collectionName), limit(itemsPerPage), orderBy(orderByAttribute));
    } else {
      q = query(collection(db, collectionName), limit(itemsPerPage), orderBy(orderByAttribute), startAfter(items[items.length - 1][orderByAttribute]));
    }

    if(searchAttribute){
      q = query(collection(db, collectionName), orderBy(searchAttribute), where(searchAttribute, ">=", searchTerm), where(searchAttribute, "<", searchTerm + 'z'));
    }

    return getDocs(q).then((querySnapshot) => {
      var newItems = [];

        querySnapshot.forEach((doc) => {
            newItems.push({
            ...doc.data(),
            id: doc.id
            });
        })

      setItems(newItems);
    });
};