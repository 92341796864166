import * as React from 'react';
import { View } from 'react-native';
import { Button, TextInput } from 'react-native-paper';

import { singleItemQuery } from '../../helpers/singleItemQuery';
import { singleItemSave } from '../../helpers/singleItemSave';

import { LocalizationDropdown } from '../../components/LocalizationDropdown';

import { deleteField } from "firebase/firestore";

export function CountriesDetailsScreen ({ navigation, route }) {
  const [id, setId] = React.useState(route.params?.id);
  const [saving, setSaving] = React.useState(false);
  const [activeLocalisation, setActiveLocalisation] = React.useState('en');
  const [document, setDocument] = React.useState(false);

  const [localizedName, setLocaizedName] = React.useState('');

  React.useEffect(() => {
    singleItemQuery("countries", id).then((document) => {
      if(document){
        setDocument(document);
      }
    })
  }, [id]);

  React.useEffect(() => {
    if(document){
      setLocaizedName(document.name_localized[activeLocalisation] ? 
       document.name_localized[activeLocalisation] : '')
    }
  }, [activeLocalisation, document]);

  const saveChanges = () => {
    const updatedDocument = {};

    if(localizedName != ""){
      updatedDocument.name_localized = document.name_localized
      updatedDocument.name_localized[activeLocalisation] = localizedName;
    } else if (document.name_localized != "") {
      updatedDocument.name_localized = document.name_localized
      updatedDocument.name_localized[activeLocalisation] = deleteField();
    }

    singleItemSave("countries", id, updatedDocument).then(() => {
      setSaving(false);
    })
  }

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center'}}>
      <LocalizationDropdown updateLanguage={(lang) => setActiveLocalisation(lang)}/>
      <TextInput
        label="id"
        placeholder='en_AU'
        value={id}
        editable={false}
      />
      <TextInput
        label="Name (Localized)"
        placeholder='Name (Localized)'
        value={localizedName}
        onChangeText={text => setLocaizedName(text)}
      />
      <Button mode="contained" onPress={saveChanges} loading={saving} disabled={saving}>
        Save Changes
      </Button>
    </View>
  );
}