import * as React from 'react';
import { View, Text } from 'react-native';

import { DataTable } from 'react-native-paper';

import { paginatedQuery } from "../../helpers/paginatedQuery";

export function LocalizationsScreen({ navigation }) {
  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    setPage(0);
  }, [itemsPerPage]);

  React.useEffect(() => {
    paginatedQuery("localizations", "friendly_name_english", items, setItems, itemsPerPage);
  }, [page, itemsPerPage]);

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center'}}>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>ID</DataTable.Title>
          <DataTable.Title>English Name</DataTable.Title>
          <DataTable.Title>Local Name</DataTable.Title>
          <DataTable.Title>Language Desginator</DataTable.Title>
          <DataTable.Title>Region Desginator</DataTable.Title>
        </DataTable.Header>

        {items.map((x, i) =>
          <DataTable.Row key={i} onPress={() => (navigation.push('LocalizationDetails', {id: x.id}))}>
            <DataTable.Cell>{x.id}</DataTable.Cell>
            <DataTable.Cell>{ x.friendly_name_english }</DataTable.Cell>
            <DataTable.Cell>{ x.friendly_name_local }</DataTable.Cell>
            <DataTable.Cell>{ x.language_designator }</DataTable.Cell>
            <DataTable.Cell>{ x.region_designator }</DataTable.Cell>
          </DataTable.Row>
        )}

        <DataTable.Pagination
          page={page}
          onPageChange={(page) => setPage(page)}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
        />
      </DataTable>
    </View>
  );
}