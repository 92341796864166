import * as React from 'react';
import { Text, View, ScrollView } from 'react-native';
import { Button, Dialog, Portal, DataTable, Checkbox } from 'react-native-paper';

import { functions } from "../../App";
import { httpsCallable } from 'firebase/functions';

export function CheckAndCreate({checkFunction, createFunction}) {
    const [visible, setVisible] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [selectedOptions, setSelectedOptions] = React.useState([]);

    const showDialog = () => setVisible(true);
    const hideDialog = () => setVisible(false);

    const handleResponse = (response) => {
        setOptions(response.data);
        showDialog();
    }

    const toggleOption = (option) => {
        if(selectedOptions.indexOf(option) != -1){
            const updatedOptions = selectedOptions;
            updatedOptions.splice(updatedOptions.indexOf(option), 1);
            setSelectedOptions(updatedOptions);
        } else {
            const updatedOptions = selectedOptions;
            updatedOptions.push(option);
            setSelectedOptions(updatedOptions);
        }
    }

    const create500 = () =>{
        var codes = [];

        for (const i in options.slice(0, 500)){
            codes.push(options[i].code)
        }

        httpsCallable(functions, createFunction)(codes)
        .then(hideDialog)
        .catch((e) => {
            hideDialog();
            console.error(e);
        })
    }

    const create300 = () =>{
        var codes = [];

        for (const i in options.slice(0, 300)){
            codes.push(options[i].code)
        }

        httpsCallable(functions, createFunction)(codes)
        .then(hideDialog)
        .catch((e) => {
            hideDialog();
            console.error(e);
        })
    }

    return (
        <View>
            <Button onPress={() => httpsCallable(functions, checkFunction)().then(handleResponse)}>Check and Create</Button>
            <Portal>
                <Dialog visible={visible} onDismiss={hideDialog}>
                    <Dialog.Title>Check and Create</Dialog.Title>
                    <Dialog.ScrollArea style={{ maxHeight: 500, paddingHorizontal: 0 }}>
                        <ScrollView>
                            <DataTable>
                                <DataTable.Header>
                                    <DataTable.Title></DataTable.Title>
                                    <DataTable.Title>Code</DataTable.Title>
                                    <DataTable.Title>Name</DataTable.Title>
                                </DataTable.Header>
                                {options.map((x, i) =>
                                    <DataTable.Row key={i}>
                                        <DataTable.Cell>
                                            <Checkbox
                                                status={selectedOptions.indexOf(x.code) != -1 ? 'checked' : 'unchecked'}
                                                onPress={() => toggleOption(x.code)}
                                            />
                                        </DataTable.Cell>
                                        <DataTable.Cell>{ x.code }</DataTable.Cell>
                                        <DataTable.Cell>{ x.name }</DataTable.Cell>
                                    </DataTable.Row>
                                )}
                            </DataTable>
                        </ScrollView>
                    </Dialog.ScrollArea>
                    <Dialog.Actions>
                        <Button onPress={create500}>Just do 500</Button>
                        <Button onPress={create300}>Just do 300</Button>
                        <Button onPress={() => httpsCallable(functions, createFunction)(selectedOptions).then(hideDialog)}>Create</Button>
                        <Button onPress={hideDialog}>Done</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        </View>
    );
}