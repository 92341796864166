import * as React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';

import { Button, Provider as PaperProvider } from 'react-native-paper';

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDP6am9J7qUfUKbUPw2CRH9fqk9ckN0F4U",
  authDomain: "a-plane-app.firebaseapp.com",
  projectId: "a-plane-app",
  storageBucket: "a-plane-app.appspot.com",
  messagingSenderId: "1020654792402",
  appId: "1:1020654792402:web:9da797b0562b074ed7470b",
  measurementId: "G-R0W3QBPNPL"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export const auth = getAuth();

const provider = new GoogleAuthProvider();

const promptSignIn = () => {
  signInWithPopup(auth, provider);
}

export const functions = getFunctions(app);

import { useAuthentication } from './src/hooks/useAuthentication';

import { HomeScreen } from "./src/screens/HomeScreen";
import { LocalizationsScreen } from "./src/screens/localization/LocalizationsScreen";
import { LocalizationDetailsScreen } from "./src/screens/localization/LocalizationDetailsScreen";
import { CountriesScreen } from "./src/screens/countries/CountriesScreen";
import { CountriesDetailsScreen } from "./src/screens/countries/CountriesDetailsScreen";
import { AirportsScreen } from "./src/screens/airports/AirportsScreen";
import { AirportsDetailsScreen } from "./src/screens/airports/AirportsDetailsScreen";
import { RegionsScreen } from "./src/screens/regions/RegionsScreen";
import { RegionsDetailsScreen } from "./src/screens/regions/RegionsDetailsScreen";
import { AirlinesScreen } from './src/screens/airline/AirlinesScreen';
import { AirlineDetailsScreen } from './src/screens/airline/AirlinesDetailsScreen';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

const Drawer = createDrawerNavigator();
const Stack = createNativeStackNavigator();

function Root() {
  return (
    <Drawer.Navigator useLegacyImplementation initialRouteName='Home'>
      <Drawer.Screen name='Home' component={ HomeScreen } />
      <Drawer.Screen name='Localizations' component={ LocalizationsScreen } />
      <Drawer.Screen name='Countries' component={ CountriesScreen } />
      <Drawer.Screen name='Airports' component={ AirportsScreen } />
      <Drawer.Screen name='Regions' component={ RegionsScreen } />
      <Drawer.Screen name='Airlines' component={ AirlinesScreen } />
    </Drawer.Navigator>
  )
}

function App() {
  const { user } = useAuthentication();

  return (
    user ? (
      <PaperProvider>
        <NavigationContainer>
          <Stack.Navigator>
            <Stack.Screen
              name="Root"
              component={Root}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="LocalizationDetails"
              component={LocalizationDetailsScreen}
            />
            <Stack.Screen
              name="CountriesDetails"
              component={CountriesDetailsScreen}
            />
            <Stack.Screen
              name="AirportsDetails"
              component={AirportsDetailsScreen}
            />
            <Stack.Screen
              name="RegionssDetails"
              component={RegionsDetailsScreen}
            />
            <Stack.Screen
              name="AirlineDetails"
              component={AirlineDetailsScreen}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </PaperProvider>
    ) : (
      <Button onPress={promptSignIn}>Sign In</Button>
    )
  );
}

export default App;