import * as React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { Subheading, Button, Dialog, Portal, RadioButton, TouchableRipple } from 'react-native-paper';

import { paginatedQuery } from "../helpers/paginatedQuery";

export function LocalizationDropdown({updateLanguage}) {
    const [language, setLanguage] = React.useState('en');
    const [visible, setVisible] = React.useState(false);
    const [localizations, setLocalizations] = React.useState([]);

    const showDialog = () => setVisible(true);
    const hideDialog = () => setVisible(false);

    React.useEffect(() => {
        updateLanguage(language);
    }, [language]);

    React.useEffect(() => {
        paginatedQuery("localizations", "friendly_name_english", localizations, setLocalizations);
    }, []);

    return (
        <View>
            <Button onPress={showDialog}>{ language } | Update Localization</Button>
            <Portal>
                <Dialog visible={visible} onDismiss={hideDialog}>
                    <Dialog.Title>Localizations</Dialog.Title>
                    <Dialog.Content>
                    {localizations.map((x, i) => (
                        <TouchableRipple onPress={() => setLanguage(x.id)} key={i}>
                            <View style={styles.row}>
                                <View pointerEvents="none">
                                    <RadioButton value={x.id} status={ x.id === language ? 'checked' : 'unchecked' } onPress={() => setLanguage(x.id)}/>
                                </View>
                                <Subheading style={styles.text}>{x.id}</Subheading>
                            </View>
                        </TouchableRipple>
                    )
                    )}
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button onPress={hideDialog}>Done</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        </View>
    );
}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 16,
        paddingVertical: 8,
    },
    text: {
        paddingLeft: 8,
    },
});