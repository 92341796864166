import * as React from 'react';
import { Text, View, Button } from 'react-native';

export function HomeScreen({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Text>Welcome to Plane App Management</Text>
      <Text>Please select what you want to manage from the left hand draw</Text>
    </View>
  );
}