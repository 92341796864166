import * as React from 'react';
import { View } from 'react-native';
import { Button, TextInput } from 'react-native-paper';

import { singleItemQuery } from '../../helpers/singleItemQuery';
import { singleItemSave } from '../../helpers/singleItemSave';

export function LocalizationDetailsScreen ({ navigation, route }) {
  const [id, setId] = React.useState(route.params?.id);

  const [englishName, setEnglishName] = React.useState("");
  const [localName, setlocalName] = React.useState("");
  const [languageDesginator, setLanguageDesginator] = React.useState("");
  const [regionDesginator, setRegionDesginator] = React.useState("");

  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    singleItemQuery("localizations", id).then((document) => {
      if(document){
        setEnglishName(document.friendly_name_english)
        setlocalName(document.friendly_name_local)
        setLanguageDesginator(document.language_designator)
        setRegionDesginator(document.region_designator)
      }
    })

  }, [id]);

  const saveChanges = () => {
    const updatedDocument = {
      friendly_name_english: englishName,
      friendly_name_local: localName,
      language_designator: languageDesginator
    };

    if(regionDesginator){
      updatedDocument.region_designator = regionDesginator;
    }

    singleItemSave("localizations", id, updatedDocument).then(() => {
      setSaving(false);
    })
  }

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center'}}>
      <TextInput
        label="id"
        placeholder='en_AU'
        value={id}
        editable={false}
      />
      <TextInput
        label="English Name"
        placeholder='English (Australian)'
        value={englishName}
        onChangeText={text => setEnglishName(text)}
      />
      <TextInput
        label="English (Australian)"
        value={localName}
        onChangeText={text => setlocalName(text)}
      />
      <TextInput
        label="en"
        value={languageDesginator}
        onChangeText={text => setLanguageDesginator(text)}
      />
      <TextInput
        label="AU"
        value={regionDesginator}
        onChangeText={text => setRegionDesginator(text)}
      />
      <Button mode="contained" onPress={saveChanges} loading={saving} disabled={saving}>
        Save Changes
      </Button>
    </View>
  );
}